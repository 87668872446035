import React from 'react'
import "./csss.css"
import chair from "../../assets/imageWebp/wynd(3).webp"
import chair2 from "../../assets/imageWebp/wynd(6).webp"
import { Link } from 'react-router-dom'
// import Fade from 'react-reveal/Fade';
import choose from "../../assets/services/choose.webp"
import abt from "../../assets/imageWebp/wynd(1).webp"
function WelcomeAboutUs() {
  return (
    <>
{/* -----------------------about--------------- */}
<div className="resta-about">
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="about-resta-inner">
          <div className="about-resta-details">
          <div className="cat-story-info">
                    <div className="cat-sect-title">
                      <h4>Our Story</h4>
                      <h2>About Udupi Palace</h2>
                    </div>
                 
                  </div>
            <div className="about-description">
              <p className="text-1">
              Established in 2001, UdupiPalace stands as the pioneering vegetarian eatery in Toronto, introducing authentic South Indian flavors to the city. Our commitment extends beyond the restaurant walls, as we offer comprehensive catering services for events ranging from intimate gatherings to extravagant weddings. 
              </p>
              <p className="text-2">
              Nestled in the bustling Gerrard Street Indian Bazaar, our restaurant is renowned for its warm hospitality and freshly prepared dishes crafted by our skilled culinary team.The Udupi Palace takes pride in its innovation and attention to detail, reflected in our extensive dosa selection boasting over 60 varieties.

              </p>
            </div>
            <div className="see-video">
              <div className="see-more-video">
                <h3>see our gallery</h3>
              </div>
              <div className="viedo--play">
                <a href="#">
                  <i className="fa fa-play" />
                </a>
              </div>
            </div>
          </div>
          <div className="about-rest-img">
            <img src={abt} alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div className="fun-facts ptb-100 text-center">
  <div className="container">
    <div className="row">
      <div className="col-lg-3 col-md-4 col-6">
        <div className="single-fun-factor">
          <div className="fun-icon">
            <a href="#">
              <i className="fa fa-coffee" />
            </a>
            <h2 className="counter">300</h2>
            <h5>Menu Items</h5>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-4 col-6">
        <div className="single-fun-factor">
          <div className="fun-icon">
            <a href="#">
              <i className="fa fa-users" />
            </a>
            <h2 className="counter">600</h2>
            <h5>Visitor Everyday</h5>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-4 col-6">
        <div className="single-fun-factor">
          <div className="fun-icon">
            <a href="#">
              <i className="fa fa-user" />
            </a>
            <h2 className="counter">400</h2>
            <h5>Expert Chef</h5>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-4 col-6 d-lg-block d-md-none d-block">
        <div className="single-fun-factor">
          <div className="fun-icon">
            <a href="#">
              <i className="fa fa-heart" />
            </a>
            <h2 className="counter">100</h2>
            <h5>Test &amp; love</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div className="choose-us">
  <div className="choose-us-top pt-100">
    <div className="container">
      <div className="row">
        <div className="col-lg-8 offset-lg-2">
          <div className="section-title white_bg mb-50 text-center">
            <h2>Why Choose Us ?</h2>
            <p>
              {" "}
              The Udupi Palace takes pride in its innovation and attention to detail, reflected in our extensive dosa selection boasting over 60 varieties.
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="choose-use-img">
            <img src={choose} alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="choose-us-desc grey-bg text-center">
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <div className="single-choose">
            <a href="#">
              <i className="fa fa-lemon-o" />
            </a>
            <h5>Authentic Flavors</h5>
            <p>
            Indulge in the rich and authentic flavors of Indian vegetarian cuisine prepared by our experienced chefs.
            </p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="single-choose">
            <a href="#">
              <i className="fa fa-cutlery" />
            </a>
            <h5>Extensive Menu</h5>
            <p>
            Enjoy a diverse and extensive menu featuring a wide range of traditional South Indian dishes, ensuring there's something for everyone to savor.
            </p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="single-choose">
            <a href="#">
              <i className="fa fa-users" />
            </a>
            <h5>Exceptional Service</h5>
            <p>
            Experience unparalleled hospitality and service in a warm and welcoming environment, where every guest is treated like family.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


</>
  )
}

export default WelcomeAboutUs
