
import  React,{ useRef, useLayoutEffect, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-fullscreen.css";
import "lightgallery/css/lg-pager.css";
import "lightgallery/css/lg-autoplay.css";
 import lightGallery from "lightgallery";
import lightImage from '../../assets/room4.jpg'
import lightImage1 from '../../assets/room3.jpeg'
import lightImage2 from '../../assets/room2.jpg'
import lightImage3 from '../../assets/conference3.webp'
import lightImage4 from '../../assets/banquet2.webp'
import lightImage5 from '../../assets/room4.jpg'
import img1 from "../../assets/imageWebp/wynd(1).webp"
import img2 from "../../assets/imageWebp/wynd(2).webp"
import img3 from "../../assets/imageWebp/wynd(10).jpg"
import img4 from "../../assets/imageWebp/wynd(11).webp"
import img5 from "../../assets/imageWebp/wynd(13).webp"
import img6 from "../../assets/imageWebp/wynd(12).webp"
import EventJson from "../Event/EventData";

function BanquetGallary() {
  const search = useLocation();
  const path = search.pathname;
  const [serviceData, setServiceData] = useState(EventJson);
  console.log(path);
  const EventsF = serviceData.find((e) => e.slug === path);
  console.log(EventsF)
  console.log(EventsF.Gal1)
  useEffect(() => {
    // Initialize lightGallery
    lightGallery(document.getElementById("image-gallery"), {
      mode: "lg-fade",
      thumbnail: true,
      zoom: false,
      autoplay: false,
    });
  }, []);
  return (
    <>
      <div style={{paddingTop:"20px"}}>
<div className="container">

          <div id="image-gallery" className="row">
          {EventsF?.Gal1.map((v)=>{
              return(
            <>
            <a
              className="col-md-4 "
              style={{ paddingBottom: "20px" }}
              // src={img1}
              data-lg-size="1600-2400"
              // data-lg-src={img1}
            >
              <img
               src={v}
                style={{ height: "300px", width: "100%" }}
                alt="Enquery"
              />
            </a>
            </>
                          )
                        })}
          </div>
        </div>
</div>
    </>
  )
}

export default BanquetGallary
