import Gallery1 from "../../src/assets/Upwas-Blog.webp";
import Gallery2 from "../../src/assets/Upwas-Blog.webp";
import Gallery3 from "../../src/assets/Upwas-Blog.webp";
import Gallery4 from "../../src/assets/Upwas-Blog.webp";
import Gallery5 from "../../src/assets/Upwas-Blog.webp";
import Gallery6 from "../../src/assets/Upwas-Blog.webp";
import Gallery7 from "../../src/assets/Upwas-Blog.webp";
import Gallery8 from "../../src/assets/Upwas-Blog.webp";
// --------------------icons----------------
import samosa from "../images/samosa.png";
import paan from "../images/paan.jpg";
import fusion from "../images/idly-manchurian.jpg";
import tandoor from "../images/paneer-tikka-2.webp";
import curry from "../images/curry.jpg";
import hot from "../images/hotdrink-removebg-preview.png";
import dosa from "../images/dosa.png";
import uthapam from "../images/utthapam.png";
import signdosa from "../images/sign-dosa.jpg";
import chaat from "../images/Chaat.webp";
import icecream from "../images/icecream-removebg-preview.png";
import dessert from "../images/Diwali-sweets-online.png";
import kids from "../images/rocketdosa-removebg-preview.png";
import thali1 from "../images/thali.avif";
import bread from "../images/butter_toast_zdish.webp";
import hakka from "../images/hakka.png";
import uthappam from "../images/utthapam-removebg-preview.png";
import rice from "../images/rice.webp";
const ServicesData = [
  {
    category: "Appetizer",
    Slugs: "/appetizer",
    iconfood: samosa,
    ServicesHeading1: [
      {
        title: "IDLI (2 Pcs)",
        price: "6.75 V",
        desc: "Steamed rice and lentil patties served with sambhar and chutney",
      },
      {
        title: "MEDHU VADA (2pcs)",
        price: "6.75 V",
        desc: "Fried lentil donughts served with sambhar and chutney",
      },
      {
        title: "RASA VADA (2pcs)",
        price: "7.25 V",
        desc: "Medhu Vada dipped in hot spicy rasam",
      },
      {
        title: "SAMBHAR VADA (2pcs)",
        price: "7.25 V",
        desc: "Medhu Vada dipped in hot sambhar",
      },
      {
        title: "DAHI VADA (1 pc)",
        price: 6.45,
        desc: "Medhu vada dipped in spiced yogurt",
      },
      {
        title: "SPECIAL BONDHA (2pcs)",
        price: "6.95 V",
        desc: "Potato dumplings with onions & lentils dipped in chick pea batter & deep fried",
      },
      {
        title: "BHAJIA",
        price: "6.50 V",
        desc: "Sliced potatoes dipped in chick pea batter & fried",
      },
      {
        title: "CHILLI PAKORAS",
        price: "6.95 V",
        desc: "Spiced large chillis in chick pea batter deep fried",
      },
      {
        title: "ASSORTED PALACE HOR-D'OEUVRES",
        price: "13.95 V",
        desc: "Idli, Bhajia, Special Bondha, Medhuvada, Chilli pakora & Onion pakora",
      },
      {
        title: "VEGETABLE CUTLET",
        price: "6.95 V",
        desc: "Finely chopped vegetable patties bread crumbed & deep fried",
      },
      {
        title: "VEGETABLE SAMOSA",
        price: "6.95 V",
        desc: "Spiced potatoes & peas stuffed in triangular pastry & deep fried",
      },
      {
        title: "ONION PAKORAS",
        price: "6.95 V",
        desc: "Chopped onions battered in chick peas flour & deep fried",
      },
      {
        title: "CHILLI PANEER ",
        price: 8.75,
        desc: "Paneer cubes tossed in green pepper, onions, garlic & soya sauce",
      },
      {
        title: "VEG SHAMI KABABS ",
        price: "7.75 V",
        desc: "Minced soy kababs with Indian spices served with mint chutney",
      },
      {
        title: "Mumbai Vada Pav",
        price: "$7.95",
      },
    ],
  },
  {
    category: "Soups",
    Slugs: "/soups",
    iconfood: hot,
    ServicesHeading1: [
      {
        title: "TOMATO SOUP",
        price: "5.75 V",
      },
      {
        title: "RASAM",
        price: "6.50 V",
      },
    ],
  },
  {
    category: "Fusion",
    Slugs: "/fusion",
    iconfood: fusion,
    ServicesHeading1: [
      {
        title: "IDLI MANCHURIAN",
        price: "8.25 V",
        desc: "Fried Idli in manchurian sauce",
      },
      {
        title: "SZECHUAN KOFTA",
        price: "8.25 V",
        desc: "Dry vegetable dumplings in szechuan sauce",
      },
      {
        title: "SWEET CORN VEG SOUP",
        price: "6.25 V",
      },
      {
        title: "SZECHUAN DOSA",
        price: "12.95 V",
        desc: "Dosa stuffed with potatoes, shredded vegetables cooked in szechuan sauce",
      },
      {
        title: "VEGETABLE FRIED RICE",
        price: "9.95 V",
        desc: "Chinese fried rice with mixed vegetables.",
      },
      {
        title: "SCHEZWAN FRIED RICE",
        price: 9.99,
      },
      {
        title: "MANCHURIAN FRIED RICE",
        price: 10.99,
        desc: "Manchurian rice with dumplings",
      },
    ],
  },
  {
    category: "Dosas",
    Slugs: "/dosas",
    iconfood: dosa,
    ServicesHeading1: [
      {
        title: "SADA DOSA ",
        price: "9.25 V",
        desc: "Thin crepe",
      },
      {
        title: "MASALA DOSA",
        price: "10.95 V",
        desc: "Thin crepe filled with spiced potatoes & onions",
      },
      {
        title: "MYSORE SADA DOSA",
        price: "9.95 V",
        desc: "Spicy chutney smeared inside Sada Dosa",
      },
      {
        title: "MYSORE MASALA DOSA",
        price: "11.75 V",
        desc: "Spicy chutney smeared inside Masala Dosa",
      },
      {
        title: "ONION SADA DOSA",
        price: 10.95,
        desc: "ONION SADA DOSA",
      },
      {
        title: "ONION MASALA DOSA",
        price: "11.75 V",
        desc: "Masala dosa smeared with raw onions",
      },
      {
        title: "GHEE SADA DOSA",
        price: 9.75,
        desc: " Dosa smeared with ghee",
      },
      {
        title: "GHEE MASALA DOSA",
        price: "11.75v",
        desc: "Masala Dosa smeared with ghee",
      },
      {
        title: "PAPER DOSA",
        price: "11.95 V",
        desc: "Large thin paper crisp crepe",
      },
      {
        title: "PAPER MASALA DOSA",
        price: "12.95 V",
        desc: "Paper dosa stuffed with spiced potatoes & onions.",
      },
      {
        title: "GHEE PAPER DOSA",
        price: 12.95,
        desc: "Large thin paper crisp dosa with ghee",
      },
      {
        title: "GHEE PAPER MASALA DOSA",
        price: 13.95,
        desc: "Large ghee paper dosa stuffed with spiced potatoes & onions",
      },
      {
        title: "SADA RAVA DOSA",
        price: "11.50 V",
        desc: "Thin & crispy crepe made with cream of wheat & rice flour",
      },
      {
        title: "ONION RAVA DOSA",
        price: "11.95 V",
        desc: "Sada rava dosa with onions.",
      },
      {
        title: "ONION RAVA MASALA DOSA",
        price: "13.55 V",
        desc: "Special rava dosa stuffed with spiced potatoes & onions",
      },
      {
        title: "SPRING DOSA",
        price: "12.95 V",
        desc: "Spicy mysore masala dosa filled with fresh vegetables",
      },
      {
        title: "PANEER DOSA",
        price: 12.95,
        desc: "Dosa stuffed with homemade cheese sautéed with onions & tomato",
      },
      {
        title: "CHILLI CHEESE DOSA",
        price: 12.95,
        desc: "Dosa smeared with spicy chutney,  cheddar & monterey jack cheese.",
      },
      {
        title: "PAV BHAJI DOSA",
        price: 12.95,
        desc: "Mashed vegetables & spiced potatoes smeared inside the Dosa",
      },
      {
        title: "Ghee Podi Dosa",
        price: "$11.95",
        desc: "",
      },
      {
        title: "Garlic Chesse Dosa	",
        price: "$12.95",
        desc: "",
      },
    ],
  },
  {
    category: "Uthappam",
    Slugs: "/uthapam",
    iconfood: uthapam,
    ServicesHeading1: [
      {
        title: "PLAIN UTHAPAM",
        price: "9.25 V",
        desc: "PLAIN UTHAPAM",
      },
      {
        title: "TOMATO & PEAS UTHAPAM",
        price: "10.99 V",
        desc: "Uthapam topped with tomato & peas",
      },
      {
        title: "ONION & HOT CHILLI UTHAPAM",
        price: "10.99 V",
        desc: "Uthapam topped with onions & hot chillies",
      },
      {
        title: "VEGETABLE UTHAPAM",
        price: "10.99V",
        desc: "Uthapam topped with mixed vegetables",
      },
      {
        title: "ONION & PEAS UTHAPAM",
        price: "10.99V",
        desc: "Uthapam topped with onions & peas",
      },
      {
        title: "MASALA UTHAPAM",
        price: "10.99V",
        desc: "Uthapam topped with potato masala",
      },
      {
        title: "PINEAPPLE UTHAPAM",
        price: "10.99 V",
        desc: "Uthapam topped with pineapple chunks",
      },
    ],
  },
  {
    category: "Beverages",
    Slugs: "/beverages",
    iconfood: hot,
    ServicesHeading1: [
      {
        title: "SODA",
        price: 2.25,
      },
      {
        title: "TEA  COFFEE  MILK",
        price: 3.25,
      },
      {
        title: "LASSI",
        price: 5.25,
        desc: "(Plain/ Sweet/ Salty)",
      },
      {
        title: "MANGO LASSI",
        price: 5.95,
      },
      {
        title: "PINEAPPLE LASSI",
        price: 5.25,
      },
      {
        title: "BANANA LASSI",
        price: 5.25,
      },
      {
        title: "FALOODA",
        price: 6.95,
      },
      {
        title: "MANGO SHAKE",
        price: 5.95,
      },
      {
        title: " MILK SHAKES",
        price: 6.25,
        desc: "(Vanilla, Chocolate, Strawberry)",
      },
      {
        title: "MANGO JUICE",
        price: 5.99,
      },
      {
        title: "FRESH LIME SODA",
        price: 5,
      },
      {
        title: "Saffron Milk / Lassi",
        price: "$5.95",
      },
      {
        title: "Chikku M/S",
        price: "$5.95",
      },
      {
        title: "Aam panna Sherbet	",
        price: "$5.00",
      },
      {
        title: "Kokam Sherbet	",
        price: "$5.00",
      },
      {
        title: "Chaas",
        price: "$3.95",
      },
      {
        title: "Jeera/T.up/Limca/Fanta",
        price: "$3.25",
      },
    ],
  },

  {
    category: "Desserts",
    Slugs: "/desserts",
    iconfood: dessert,
    ServicesHeading1: [
      {
        title: "RASMALAI",
        price: 5.25,
      },
      {
        title: "GULAB JAMUN",
        price: 5.25,
      },
      {
        title: "GULAB JAMUN w ICECREAM",
        price: 6.25,
      },
      {
        title: "CARROT HALWA",
        price: 5.25,
      },
      {
        title: "CARROT HALWA w ICECREAM",
        price: 6.25,
      },
      {
        title: "ICE CREAM",
        price: 3.95,
      },
      {
        title: "DESSERT OF THE DAY",
        price: 4.25,
      },
      {
        title: "KULFI",
        price: 4.95,
      },
    ],
  },

  {
    category: "Extra Breads",
    Slugs: "/breads",
    iconfood: bread,
    ServicesHeading1: [
      {
        title: "CHAPATI",
        price: "1.75 V",
      },
      {
        title: "POORI",
        price: "2.65 V",
      },
      {
        title: "PARATHA",
        price: "3.95 V",
      },
      {
        title: "ALOO PARATHA",
        price: "5.55 V",
      },
      {
        title: "BHATURA",
        price: "3.95 V",
      },
      {
        title: "PAV",
        price: 1,
      },
    ],
  },
  {
    category: "Weekends Only",
    Slugs: "/weekends",
    iconfood: samosa,
    ServicesHeading1: [
      {
        title: "NAAN",
        price: 3.75,
      },
      {
        title: "BUTTER NAAN",
        price: 4.25,
      },
      {
        title: "GARLIC NAAN",
        price: 4.5,
      },
    ],
  },
  {
    category: "Extras",
    Slugs: "/extra",
    ServicesHeading1: [
      {
        title: "RAITA",
        price: 3.75,
      },
      {
        title: "RICE",
        price: 3.25,
      },
      {
        title: "PAPAD  (2)",
        price: 1.25,
      },
      {
        title: "SAMBHAR  (4oz)",
        price: 2,
      },
      {
        title: "SAMBHAR (8oz)",
        price: 3.99,
      },
      {
        title: "CHUTNEY (4 oz)",
        price: 2,
      },
    ],
  },
  //       {
  //        category: "Weekends Only",
  //         ServicesHeading1:[
  //           {
  //      title: "PLAIN UTHAPAM",
  //      price: "9.25 V"
  //     },
  //     {
  //      title: "TOMATO & PEAS UTHAPAM",
  //      price: "10.99 V",
  //     desc: "Uthapam topped with tomato & peas"
  //     },

  //     {
  //      title: "ONION & HOT CHILLI UTHAPAM",
  //      price: "10.99 V",
  //     desc: "Uthapam topped with onions & hot chillies"
  //     },

  //     {
  //      title: "VEGETABLE UTHAPAM",
  //      price: "10.99V",
  //     desc: "Uthapam topped with mixed vegetables"
  //     },

  //     {
  //      title: "ONION & PEAS UTHAPAM",
  //      price: "10.99V",
  //     desc: "Uthapam topped with onions & peas"
  //     },

  //     {
  //      title: "MASALA UTHAPAM",
  //      price: "10.99V",
  //     desc: "Uthapam topped with potato masala"
  //     },

  //     {
  //      title: "PINEAPPLE UTHAPAM",
  //      price: "10.99 V",
  //     desc: "Uthapam topped with pineapple chunks"
  //     },
  //   ],
  // },
  {
    category: "KIDS CORNER",
    Slugs: "/kids",
    iconfood: kids,
    ServicesHeading1: [
      {
        title: "CHEESE DOSA",
        price: 11.45,
        desc: "Sada Dosa smeared with cheese served with Tomato Ketchup",
      },

      {
        title: "JUM JUM DOSA",
        price: "8.75V",
        desc: "Sada Dosa smeared with Fruit jam",
      },

      {
        title: "FRENCH FRIES",
        price: "4.95 V",
      },
      {
        title: "Chocolate Dosa",
        price: "$8.75",
        desc: "",
      },
    ],
  },
  {
    category: "TANDOOR",
    Slugs: "/tandoor",
    iconfood: tandoor,
    ServicesHeading1: [
      {
        title: "PANEER TIKKA",
        price: 13.99,
        desc: "Paneer cubes marinated & cooked in Tandoor",
      },
    ],
  },

  {
    category: "MEALS",
    Slugs: "/meals",
    iconfood: thali1,
    ServicesHeading1: [
      {
        title: "SOUTH INDIAN THALI",
        price: 15.25,
        desc: "A complete meal of two vegetables, dal, rasam. sambhar, white rice, chapati / poori, papad & dessert.( V & GF available) ",
      },
      {
        title: "SPECIAL THALI",
        price: 19.25,
        desc: "A complete meal of 3 vegetables from curry menu, dal, rasam. sambhar, white rice, chapati / poori, papad & dessert.( V & GF available",
      },
      {
        title: "PAV BHAJI",
        price: 10.95,
        desc: "Mashed vegetables & spiced potatoes served with dinner rolls",
      },

      {
        title: "PANEER PAV BHAJI",
        price: 12.95,
        desc: "Mashed vegetables with paneer & spiced potatoes served with dinner rolls",
      },
      {
        title: "CHEESE PAV BHAJI",
        price: 12.95,
        desc: "Mashed vegetables with CHEESE & spiced potatoes served with dinner rolls",
      },
      {
        title: "CHANA BHATURA",
        price: "11.95 V",
        desc: "Chick peas cooked in a delicious curry served with two bhaturas",
      },
      {
        title: "POORI BHAJI",
        price: "9.95 V",
        desc: "Potatoes, peas & onions masala served with poori's",
      },
      {
        title: "CHANA POORI",
        price: "10.95 V",
        desc: "Chana Masala with poori",
      },
    ],
  },
  {
    category: "CURRY",
    Slugs: "/curry",
    iconfood: curry,
    ServicesHeading1: [
      {
        title: "SAAG PANEER",
        price: 10.25,
        desc: "Spinach cooked with homemade cheese & spices",
      },

      {
        title: "MUTTER PANEER",
        price: 10.25,
        desc: "Green Peas & homemade cheese cooked in curry",
      },

      {
        title: "ALOO SAAG",
        price: "9.95 V",
        desc: "Potatoes & spinach cooked in a delicious curry",
      },

      {
        title: "CHANA MASALA",
        price: "9.95 V",
        desc: "Chick peas cooked in a delicious curry",
      },

      {
        title: "ALOO MUTTER",
        price: "9.95 V",
        desc: "Potatoes & green peas cooked in a curry",
      },

      {
        title: "SPECIAL VEGETABLE CURRY",
        price: "9.95 V",
        desc: "Garden fresh vegetables cooked in coconut gravy",
      },

      {
        title: "YELLOW DAL",
        price: "9.25 V",
        desc: "Yellow lentils cooked in tempered ginger, garlic, mustard seeds curry",
      },

      {
        title: "ALOO GOBI",
        price: "9.95 V",
        desc: "Cauliflower & potatoes cooked in thick spiced gravy",
      },

      {
        title: "KADAI BHINDI",
        price: "9.95 V",
        desc: "Okra cooked in onions & tomatoes gravy",
      },

      {
        title: "VEGETABLE MAKHNI",
        price: 9.95,
        desc: "Mixed vegetables cooked in a rich tomato based gravy",
      },

      {
        title: "PANEER MAKHNI",
        price: 10.25,
        desc: "Paneer cubes cooked in a rich tomato based gravy",
      },
    ],
  },

  {
    category: "Rice Specialities",
    Slugs: "/rice",
    iconfood: rice,
    ServicesHeading1: [
      {
        title: "BISI BELE BATH",
        price: 9.75,
        desc: "Rice cooked with lentils & garden vegetables",
      },

      {
        title: "LEMON RICE",
        price: "9.75 V",
        desc: "Basmati rice flavoured with lemon",
      },

      {
        title: "BAGALA BATH (Served Cold)",
        price: 9.75,
        desc: "Yogurt rice mixed with mustard seeds",
      },

      {
        title: "PONGAL",
        price: 9.95,
        desc: "Rice & lentils cooked with mild spices & seasonings",
      },

      {
        title: "UPMA",
        price: 9.95,
        desc: "Cream of wheat cooked with mixed vegetables",
      },

      {
        title: "VEGETABLE PULAO",
        price: 10.25,
        desc: "Rice cooked with spiced vegetables.",
      },
    ],
  },
  {
    category: "Sides",
    Slugs: "/Sides",
    iconfood: paan,
    ServicesHeading1: [
      {
        title: "Paan",
        price: "$3.00",
      },

      {
        title: "Gadbad I/C",
        price: "$10.95",
      },
    ],
  },
];

export default ServicesData;
