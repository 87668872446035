import React from 'react'
import "./csss.css"
import chair from "../../assets/imageWebp/wynd(9).jpg"
import chair2 from "../../assets/imageWebp/wynd(8).jpg"
import { Link } from 'react-router-dom'
// import Fade from 'react-reveal/Fade';

function WelcomeBanquet() {
  return (
<section id="about" className="pt-14 pb-14">
  <div className="container">
    <div
      className="row align-items-start align-items-md-stretch"
      data-cues="fadeIn"
      data-disabled="true"
    >
      <div
        className="col-12 col-lg-6 order-1 order-lg-0"
        data-cue="fadeIn"
        data-show="true"
        style={{
          animationName: "fadeIn",
          animationDuration: "1000ms",
          animationTimingFunction: "ease",
          animationDelay: "0ms",
          animationDirection: "normal",
          animationFillMode: "both"
        }}
      >
        <div
          className="react-reveal row g-0"
          style={{
            animationFillMode: "both",
            animationDuration: "1000ms",
            animationDelay: "0ms",
            animationIterationCount: 1,
            opacity: 1,
            animationName: "react-reveal-696799997875020-1"
          }}
        >
          <div className="col-lg-6 col-12 order-md-1 order-2">
            <figure className="mb-0 pe-3 pe-md-4 pe-lg-5">
            
            {/* <Fade left>  */}
            <img
                src={chair}
               
                className="img-fluid w-100 rounded shadow-sm"
                alt=""
              />
              {/* </Fade> */}
            </figure>
          </div>
          <div className="col-lg-6 col-12 order-md-1 order-2">
            <figure className="mb-0 pt-9">
            {/* <Fade left>  */}
              <img
                src={chair2}
               
                className="img-fluid w-100 rounded shadow-sm "
                alt=""
              />
              {/* </Fade> */}
            </figure>
          </div>
        </div>
      </div>
      <div
        className="col-12 col-lg-6 order-0 order-lg-1 order-md-2 order-1"
        data-cue="fadeIn"
        data-show="true"
        style={{
          animationName: "fadeIn",
          animationDuration: "1000ms",
          animationTimingFunction: "ease",
          animationDelay: "0ms",
          animationDirection: "normal",
          animationFillMode: "both"
        }}
      >
        <div
          className="react-reveal bg-white h-100 d-flex align-items-center"
          style={{
            animationFillMode: "both",
            animationDuration: "1000ms",
            animationDelay: "0ms",
            animationIterationCount: 1,
            opacity: 1,
            animationName: "react-reveal-696799997875020-2"
          }}
        >
          <div className="p-lg-5 mb-8">
            {/* <Fade left > */}
            <div className="cat-story-info">
                    <div className="cat-sect-title">
                      <h4>Events</h4>
                      <h2>Organize Your Event With Us</h2>
                    </div>
                    <p className="mb-3">
                      Consectetur adipisicing elit, sed do eiusmod tempor
                      incididunt ut labore eit esdioilore magna aliqua. Ut enim
                      ad minim veniam, quis exercitation ullaemco laboeeiris
                      nisi ut aliquip ex ea commodo consequat. Duis aute irure
                      dolor iesdein reprehendeerit in voluptate velit esse
                      cillum dolore.
                    </p>
                    <p>
                      Ut enim ad minim veniam, quis nostrud exercitation
                      ullaemco laboeeiris nisi ut aliquip ex ea commodo
                      consequat.adipisicing elit, sed do eiusmod tempor
                      incididunt ut labore eit esdioilore magna aliqua.
                    </p>
                    
                  </div>
            <Link to='/aboutus'>
            {/* <a role="button" tabIndex={0} href="#!" className="custom-btn ">  */}
            <div className="order-now">
                    <a href="javascript:void(0)">order now</a>
                  </div>
            {/* </a> */}
            </Link>
            {/* </Fade> */}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


  )
}

export default WelcomeBanquet
