import React from 'react'

import Welcome from '../Welcome/Welcome'

import Footer from '../Footer/Footer'
// import Amenity from '../Amenity/Amenity'
 import Banner from '../Banner/Banner'



function MainHome() {
  return (
    <> 
      <Banner/>
      <Welcome/>
      
      <Footer/>
    </>
  )
}

export default MainHome
