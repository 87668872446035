import room1 from "../../assets/room2.jpg";

import confrence1 from "../../assets/conference 1.jpg";

import corporate1 from "../../assets/imageWebp/speaker-business-conference-presentation-audience-600nw-217119211.webp";

import wedding2 from "../../assets/wedding2.webp";
import imges1 from "../../assets/imageWebp/wynd(1).webp";
import imges2 from "../../assets/imageWebp/wynd(2).webp";
import imges3 from "../../assets/imageWebp/wynd(3).webp";
import imges4 from "../../assets/imageWebp/wynd(4).webp";
import imges5 from "../../assets/imageWebp/wynd(5).webp";
import imges6 from "../../assets/imageWebp/wynd(6).webp";
import imges7 from "../../assets/imageWebp/wynd(7).webp";
import imges8 from "../../assets/imageWebp/wynd(8).jpg";
import imges9 from "../../assets/imageWebp/wynd(9).jpg";
import imges10 from "../../assets/imageWebp/wynd(10).jpg";
import imges11 from "../../assets/imageWebp/wynd(11).jpg";
import imges12 from "../../assets/imageWebp/wynd(12).jpg";
import imges13 from "../../assets/imageWebp/wynd(13).jpg";
import imges14 from "../../assets/imageWebp/wynd(14).jpg";
import imges15 from "../../assets/imageWebp/wynd(15).jpg";
import imges16 from "../../assets/imageWebp/wynd(16).jpg";
import imges17 from "../../assets/imageWebp/wynd(17).jpg";
import imges18 from "../../assets/imageWebp/wynd(18).jpg";
import imges19 from "../../assets/imageWebp/wynd(19).jpg";
import imges20 from "../../assets/imageWebp/wynd(20).jpg";
import imges21 from "../../assets/imageWebp/wynd(21).jpg";
import imges22 from "../../assets/imageWebp/wynd(22).jpg";
import imges23 from "../../assets/imageWebp/wynd(23).jpg";
import imges24 from "../../assets/imageWebp/wynd(24).jpg";
import imges25 from "../../assets/imageWebp/wynd(25).webp";
import imges26 from "../../assets/imageWebp/wynd(26).webp";
import imges27 from "../../assets/imageWebp/wynd(27).jpg";

const GalleryData = [
  // {
  //   id: 1,
  //   Category: "Rooms",
  //   gImage1: room1,
  //   gImage2: room1,
  //   gImage3: room1,
  //   gImage4: room1,
  //   gimagename: "Room Name",
  // },
  {
    id: 2,
    Category: "Conference",
    gImage1: confrence1,
    gimagename: "Conferece Name",
  },
  {
    id: 3,
    Category: "Corporate",
    gImage1: corporate1,
    gimagename: "Coroporate",
  },
  {
    id: 4,
    Category: " Weddings",
    gImage1: wedding2,
    gimagename: "Wedding",
  },
  { id: 1, Category: "Conference", gImage1: imges1, gimagename: "Conferece Name" },
  { id: 2, Category: "Conference", gImage1: imges2, gimagename: "Conferece Name" },
  { id: 3, Category: "Conference", gImage1: imges3, gimagename: "Conferece Name" },
  { id: 4, Category: "Conference", gImage1: imges4, gimagename: "Conferece Name" },
  { id: 5, Category: "Conference", gImage1: imges5, gimagename: "Conferece Name" },
  { id: 6, Category: "Conference", gImage1: imges6, gimagename: "Conferece Name" },
  { id: 7, Category: "Conference", gImage1: imges7, gimagename: "Conferece Name" },
  { id: 8, Category: "Conference", gImage1: imges8, gimagename: "Conferece Name" },
  { id: 9, Category: "Conference", gImage1: imges9, gimagename: "Conferece Name" },
  { id: 10, Category: "Conference", gImage1: imges10, gimagename: "Conferece Name" },
  { id: 11, Category: "Conference", gImage1: imges11, gimagename: "Conferece Name" },
  { id: 12, Category: "Conference", gImage1: imges12, gimagename: "Conferece Name" },
  { id: 13, Category: "Conference", gImage1: imges13, gimagename: "Conferece Name" },
  { id: 14, Category: "Conference", gImage1: imges14, gimagename: "Conferece Name" },
  { id: 15, Category: "Conference", gImage1: imges15, gimagename: "Conferece Name" },
  { id: 16, Category: "Conference", gImage1: imges16, gimagename: "Conferece Name" },
  { id: 17, Category: "Conference", gImage1: imges17, gimagename: "Conferece Name" },
  { id: 18, Category: "Conference", gImage1: imges18, gimagename: "Conferece Name" },
  { id: 19, Category: "Conference", gImage1: imges19, gimagename: "Conferece Name" },
  { id: 20, Category: "Conference", gImage1: imges20, gimagename: "Conferece Name" },
  { id: 21, Category: "Conference", gImage1: imges21, gimagename: "Conferece Name" },
  { id: 22, Category: "Conference", gImage1: imges22, gimagename: "Conferece Name" },
  { id: 23, Category: "Conference", gImage1: imges23, gimagename: "Conferece Name" },
  { id: 24, Category: "Conference", gImage1: imges24, gimagename: "Conferece Name" },
  { id: 25, Category: "Conference", gImage1: imges25, gimagename: "Conferece Name" },
  { id: 26, Category: "Conference", gImage1: imges26, gimagename: "Conferece Name" },
  { id: 27, Category: "Conference", gImage1: imges27, gimagename: "Conferece Name" },

];

export default GalleryData;
