import React from 'react'
import About from '../Components/About/About'



function AboutUs() {
  return (
    <div>
      <About/>
      
    </div>
  )
}

export default AboutUs
