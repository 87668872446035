import React from 'react'
 import Contact from '../Components/Contact/Contact'

function ContectUs() {
  return (
    <>
      <Contact/>
    </>
  )
}

export default ContectUs
