import React, { useState, useEffect } from "react";
import "../NavBar/Navbar.css";
import { Nav, Navbar, Container } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import EventData from "../Event/EventData";
import Logo from "../../images/Udupi (1) (2).png";
import JsonBanquet2 from "../Banquet/JsonBanquet2";
import ServiceData from "../ServicesData";
const NavBar = () => {
  const [color, setColor] = useState(false);
  const className = "dropdownShow";
  // const textColor = "text-black";
  useEffect(() => {
    const changeColor = () => {
      if (window.scrollY >= 78) {
        setColor(true);
      } else {
        setColor(false);
      }
    };
    window.addEventListener("scroll", changeColor);

    return () => {
      window.removeEventListener("scroll", changeColor);
    };
  }, []);

  return (
    <div>
      {/* -------------------------header----------------- */}

      {/* ------------------------------------------------------ */}

      <section className="NavSection">
        <div className="container-fluid">
          <Navbar
            collapseOnSelect
            expand="lg"
            variant="dark"
            className={color ? "NavBar NavBar-bg" : "NavBar"}
          >
            <Container>
              <Navbar.Brand href="/">
                <img src={Logo} alt="" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav" className="main-nav">
                <Nav>
                  <Nav.Link href="/">
                    <Link to="/">Home</Link>
                  </Nav.Link>
                  <Nav.Link href="/appetizer">
                    <Link
                      className={`oderButton`}
                      to="https://gosnappy.io/owa/snappy/detail/G1544/1544/"
                    >
                      Order Now
                    </Link>
                  </Nav.Link>
                  <Nav.Link href="/about-us">
                    <Link to="/about-us">About Us</Link>
                  </Nav.Link>
                  <Nav.Link to="#">
                    <Link
                      to="#"
                      className={`${className} ${
                        color ? "text-black" : "text-white"
                      }`}
                    >
                      Service
                      <div className="dorpdown">
                        <div className="dropdown_list">
                          {EventData.map((v, i) => {
                            return (
                              <>
                                <Nav.Link href={v.slug}>
                                  <Link to={v.slug}>{v.h1}</Link>
                                </Nav.Link>
                              </>
                            );
                          })}
                          {/* <Nav.Link href="/Greenfarm">
                          <Link to="/Greenfarm">Birthday</Link>
                        </Nav.Link>
                        <Nav.Link href="Royalfarm">
                          <Link to="Royalfarm">Wedding</Link>
                        </Nav.Link> */}
                        </div>
                      </div>
                    </Link>
                  </Nav.Link>
                  {/* <Nav.Link href="/Banquet">
                    <Link   to="/Banquet">Events</Link>
                  </Nav.Link> */}
                  {/* <Nav.Link to="#">
                    <Link
                      to="#"
                      className={`${className} ${
                        color ? "text-black" : "text-white"
                      }`}
                    >
                      Menu
                      <div className="dorpdown">
                        <div className="dropdown_list">
                          {ServiceData.map((v, i) => {
                            return (
                              <>
                                <Nav.Link href={v.Slugs}>
                                  <Link to={v.Slugs}>{v.category}</Link>
                                </Nav.Link>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </Link>
                  </Nav.Link> */}
                  {/* <Nav.Link href="/">
                    <Link   to="/Menu">Menu</Link>
                  </Nav.Link> */}
                  <Nav.Link href="/">
                    <Link to="/gallery">Events</Link>
                  </Nav.Link>

                  {/* <Nav.Link href="/home-care-nursing-services">
                      <Link to="/home-care-nursing-services">Gallery</Link>
                    </Nav.Link> */}
                  <Nav.Link href="/ContectUs">
                    <Link to="/contact-us">Contact Us</Link>
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </section>
    </div>
  );
};

export default NavBar;
