import React, { useState } from "react";
// import Resizer from "react-image-file-resizer";
// import { useImageResizer } from "../Context/imageResizer";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal } from "antd";
// import $ from "jquery";
import scanner from "../assets/reserv-form-bg.webp"

const EnquiryModal = ({}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleItemSelect = (item) => {
    setSelectedItems(prevItems => [...prevItems, item]); // Add selected item to the state
  };
  console.log(selectedItems)
  // -----------------image resizer--------------------
  // const [profileFile, setProfileFile] = useState(null);
  // const { resizeFile } = useImageResizer();
  // const handleImageUpload = async (event) => {
  //   const maxWidth = 600;
  //   const maxHeight = 600;
  //   const targetFileSize = 60 * 1024;

  //   const compresedFile = await resizeFile(
  //     event.target.files[0],
  //     maxWidth,
  //     maxHeight,
  //     "WEBP",
  //     targetFileSize
  //   );
  //   setProfileFile(compresedFile);
  //   console.log(URL.createObjectURL(compresedFile));
  // };

  // console.log(profileFile);

  // email
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const formData = new FormData();
    // const addressDetails =
    //   values.address +
    //   " " +
    //   values.city +
    //   " " +
    //   values.state +
    //   " " +
    //   values.pinCode;
      // const amountValue = values.amount || donationValue;
    const body =
      '<!DOCTYPE html><html><head><title>Order Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#659494">The UDUPI PALACE</h2><p>Hello</p><p>Please check your order which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left">' +
      values.fullName +
      '</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left">' +
      values.email +
      '</td></tr><tr><td style="text-align:left"><strong>Contact:</strong></td><td style="text-align:left">' +
      values.phone +
    
      '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at Shree Krishna DIgital Marketing</p></div></body></html>';
      body += '<p style="font-size:16px;font-weight:600;color:#09a2a4">Selected Items:</p>';
    selectedItems.forEach(item => {
      body += '<p style="font-size:14px;font-weight:500;color:#111">' + item + '</p>';
    });
    formData.append("mailBody", body);
 
    formData.append("toEmail", "princygrwl4@gmail.com");
 
    formData.append("fromEmail", "skdmlead@gmail.com");
    formData.append("bccMail", "skdmlead@gmail.com");
    formData.append("mailSubject", "New donation from website");
    formData.append("accountName", "katha");
    formData.append("accountLeadSource", "https://skdm.in");
    formData.append("accountLeadName", "custName");

    const resp = await fetch("https://skdm.in/server/v1/send_lead_mail.php", {
      method: "post",
      body: formData,
    });
    alert("Your Form has Submited Our team will contact with You  soon.");
    console.log(resp);
    // console.log(addressDetails);
    form.resetFields();
    setIsModalOpen(false);
  };

  // console.log("donationValue",donationValue);

  return (
    <>
      <Button type="danger" className="orderBtn" onClick={showModal} >
        Send Menu
      </Button>

      <Modal
        title=""
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
        footer={[]}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="scanner">
                <img src={scanner} alt="" />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <Form form={form} layout="vertical" onFinish={onFinish}>
                <div className="row">
                  <div className="col-md-6 col-12">
                    <Form.Item
                      name="fullName"
                      label="Full Name"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Full Name" />
                    </Form.Item>
                  </div>
                  <div className="col-md-6 col-12">
                    <Form.Item
                      name="phone"
                      label="Phone"
                      rules={[
                        { required: true },
                        {
                          pattern: /^[0-9]{10}$/,
                          message: "Please enter a valid phone number",
                        },
                      ]}
                      tooltip={{
                        title: "Tooltip with customize icon",
                        icon: <InfoCircleOutlined />,
                      }}
                    >
                      <Input placeholder="+91" />
                    </Form.Item>
                  </div>
                </div>

                <div className="row">
                  <div className=" col-12">
                    <Form.Item
                      name="email"
                      label="Email"
                      rules={[{ type: "email", required: true }]}
                      tooltip={{
                        title: "Tooltip with customize icon",
                        icon: <InfoCircleOutlined />,
                      }}
                    >
                      <Input placeholder="abc@email.com" />
                    </Form.Item>
                  </div>
                
                </div>

                <Form.Item
                  name="address"
                  label="Address"
                  rules={[{ required: true }]}
                >
                  <Input.TextArea placeholder="Enter your address" />
                </Form.Item>

                <div className="row">
                
                  <div className="col-12">
                    <Form.Item
                      name="message"
                      label="Message"
                      rules={[{ required: true }]}
                    >
                      <Input.TextArea placeholder="Message" />
                    </Form.Item>
                  </div>
              
                </div>
               

                <Form.Item>
                  <Button htmlType="submit" className="">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EnquiryModal;
