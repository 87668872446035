import React from 'react'
import '../Banner/Banner.css'

function BannerA() {
  return (
    <>
    <div className="breadcrubs ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcurbs-inner text-center">
                            <h3 className="breadcrubs-title">
                          About Us
                            </h3>
                            <ul>
                                <li><a href="index.html">home <span>//</span>  </a></li>
                                <li>About</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      {/* <section
  id="breadcrumb-section"
  className="breadcrumb-section clearfix margintop"
>
  <div
    className="jarallax"
    style={{
      backgroundImage:
      `url(${aboutBan})`,
      backgroundSize: "cover",
      zIndex: 0
    }}
  >
    <div className="overlay-black">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="breadcrumb-title text-center mb-50">
              <span className="sub-title"></span>
              <h2 className="big-title">
                <strong>About us </strong>
              </h2>
            </div>
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-item">
                  <a href="index-1.html" className="breadcrumb-link">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  about us
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  </div>
</section> */}

    </>
  )
}

export default BannerA
