import "./App.css";
import MainHome from "./Components/Main_Home/MainHome";
import { Button, ConfigProvider, Space } from 'antd';

// import 'bootstrap/dist/css/bootstrap.css';
import Hadder from "./Components/NavBar/NavBar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AboutUs from "./Pages/AboutUs";
import Services from "./Components/Services/Services.jsx";
import ContectUs from "./Pages/ContectUs";
import Gallery from "./Pages/Gallery.jsx";
import banquentData from "./Components/Banquet/JsonBanquet2.jsx";
import Banquet from "./Components/Banquet/Banquet.jsx";
import EventData from "./Components/Event/EventData.jsx";
import Event from "./Components/Event/Event.jsx";
// import 'bootstrap/dist/css/bootstrap.min.css';
import ServiceData from "./Components/ServicesData.jsx";
import Menu from "./Components/Services/Services.jsx";
import Popmodal from "./Components/Popmodal.jsx";

function App() {
  return (
    <>
      <BrowserRouter>
        <Hadder />
        <Popmodal />
        <Routes>
          <Route path="/" element={<MainHome />}></Route>
          <Route path="/gallery" element={<Gallery />}></Route>
          <Route path="/about-us" element={<AboutUs />}></Route>
          <Route path="/contact-us" element={<ContectUs />}></Route>
          <Route path="/banquet" element={<Banquet />}></Route>
          <Route path="/menu" element={<Menu />}></Route>

          {ServiceData.map((route, index) => (
            <Route
              key={index}
              exact
              path={route.Slugs}
              element={
                  <ConfigProvider
                    theme={{
                      components: {
                        Modal: {
                          /* here is your component tokens */
                          
                        },
                      },
                    }}
                  >
                    <Services />
                  </ConfigProvider>

              }
            />
          ))}

          {EventData.map((v, i) => {
            // console.log(v.slug)
            return (
              <>
                <Route key={i} exact path={v.slug} element={ 
                <ConfigProvider
                    theme={{
                      components: {
                        Modal: {
                          /* here is your component tokens */
                          
                        },
                      },
                    }}
                  >
                   <Event />
                  </ConfigProvider>
               }></Route>
              </>
            );
          })}

          {/* <Route path='/' */}
          {/* <Route path='/Banquet' element={<Banquetp/>}/> */}
          {/* {EventData.map((v,i)=>{
      // console.log(v.slug)
      return(<>
      <Route 
      key={i}
      exact
      path={v.slug}
      element={<EventPage content={v.Content} greet={v.h1} />}
      ></Route> 
      </>)
     })} */}
          {/* {EventData.map((v,i)=>{
      return(
        <>
        <Route>
          key={i}
          exact
          path ={v.slug}
          element ={<EventWelcome />}
        </Route>
        </>
      )
     })} */}
          {/* <Route path='/Evente' element={<EventPage/>}/> */}
          {/* <Route path='/BanquitTwo' element={<Page2/>}/>
     <Route path='/BanquetThree' element={<PageB3/>}/> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
