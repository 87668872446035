import birthday from "../../assets/imageWebp/wynd(1).webp";

import corporate1 from "../../images/eventspace.jpg";

import wedding2 from "../../images/tiffin.jpg";
import galleryf1 from "../../images/delivery/food1.jpg";
import galleryf2 from "../../images/delivery/food2.jpg";
import galleryf3 from "../../images/delivery/food3.jpg";
import galleryf4 from "../../images/delivery/food4.jpg";

import gallery1 from "../../assets/catering2.jpg";
import gallery2 from "../../assets/Upwas-Blog.webp";
import gallery3 from "../../assets/Upwas-Blog.webp";
import gallery4 from "../../assets/Upwas-Blog.webp";
import gallery5 from "../../assets/Upwas-Blog.webp";
import gallery6 from "../../assets/Upwas-Blog.webp";

import galleryt1 from "../../images/tiffin/t1.jpg";
import galleryt2 from "../../images/tiffin/t2.jpg";
import galleryt3 from "../../images/tiffin/t3.jpg";
import galleryt4 from "../../images/tiffin/t4.png";
import galleryt5 from "../../images/tiffin/t5.webp";
import galleryt6 from "../../images/tiffin/t6.jpg";

import galleryr1 from "../../images/restaurant/1.jpg";
import galleryr2 from "../../images/restaurant/2.jpg";
import galleryr3 from "../../images/restaurant/3.jpg";
import galleryr4 from "../../images/restaurant/4.jpg";
import galleryr5 from "../../images/restaurant/5.jpg";
import galleryr6 from "../../images/restaurant/6.jpg";

import galleryc1 from "../../images/catering/c1.jpg";
import galleryc2 from "../../images/catering/c2.jpg";
import galleryc3 from "../../images/catering/c3.webp";
// --------------------icons----------------
import samosa from "../../images/samosa.png";
import dessert from "../../images/Diwali-sweets-online.png";
import bread from "../../images/butter_toast_zdish.webp";
import rice from "../../images/rice.webp";
import curry from "../../images/curry.jpg";

import categ1 from "../../assets/gallery/1.jpeg";
import categ2 from "../../assets/gallery/2.jpeg";
import categ3 from "../../assets/gallery/3.jpeg";
import categ4 from "../../assets/gallery/4.jpeg";
import categ5 from "../../assets/gallery/5.jpeg";
import categ6 from "../../assets/gallery/6.jpeg";
import categ7 from "../../assets/gallery/7.jpeg";
import categ8 from "../../assets/gallery/8.jpeg";
import categ9 from "../../assets/gallery/9.jpeg";

const EventData = [
  {
    id: 1,
    slug: "/restaurant",
    h1: "Restaurant",
    greet: "Welcome To Birthday",
    Header: "Restaurant",

    seoPara:
      "Located in the prime location of Toronto at Gerrard Street Indian Bazaar, our restaurant proudly stands as the first vegetarian destination in the city, offering authentic South Indian cuisine. Renowned for our hospitality and impeccable service, we strive to create a welcoming environment where every guest feels like family. At our establishment, we prioritize freshness and quality, ensuring that every dish is prepared with care by our talented team of staff. From aromatic curries to crispy dosas and fluffy idlis, each meal is a testament to our dedication to providing an unparalleled dining experience. Additionally, we feature weekend specials to delight our patrons with unique and seasonal offerings, adding excitement to every visit.Our commitment to inclusivity extends to our menu, as we cater to a diverse range of dietary preferences. Whether you're vegan, gluten-free, or simply looking for wholesome vegetarian fare, our restaurant has something to satisfy every palate. Moreover, we take pride in offering a kid-friendly environment, making dining out with the family a joyous occasion for all. Join us at our South Indian haven in the heart of Toronto, where tradition meets innovation, and every bite tells a story of flavor and authenticity. ",
    imageRight: galleryr2,
    Gal1: [galleryr1, galleryr2, galleryr3, galleryr4, galleryr5, galleryr6],
    Menu: [],
  },
  {
    id: 2,
    slug: "/food-intrays",
    h1: "Food In Trays",
    greet: "Welcome To Birthday",
    note: "Food In Trays available at 1 day notice",
    Header: "Food In Trays",
    Content:
      "Welcome to our Wynd event,a top event organisers in Andheri , where the joy of celebrations takes center stage, and birthdays become unforgettable moments of laughter and merriment. With an ambiance designed to infuse festivities with flair, our venue is the perfect canvas for creating memories that last a lifetime.At the heart of our commitment is the understanding that birthdays are milestones meant to be celebrated in style. Our banquet hall, with its capacity to host lively gatherings, transforms into a haven of joy where every detail is crafted to make your birthday party an extraordinary affair. Step into an environment where vibrant decor meets versatile spaces, catering to themed parties, intimate gatherings, or grand celebrations. From the moment you enter, you'll sense the air of excitement and anticipation, as top event organisers in Andheri work tirelessly to ensure your vision for the perfect birthday comes to life.",
    seoPara:
      "At  Udupi Palace, we understand the importance of family gatherings and the joy that comes from sharing a meal together. That's why we offer convenient bulk food options tailored to accommodate gatherings ranging from 10 to 60 guests. Whether you're celebrating a milestone birthday, hosting a family reunion, or simply craving a feast with loved ones, our bulk food service ensures that everyone is well-fed and satisfied. From flavorful curries and aromatic rice dishes to crispy snacks and indulgent desserts, our extensive menu caters to diverse palates and dietary preferences, ensuring there's something for everyone to enjoy. With The Udupi Palace's bulk food service, you can focus on creating cherished memories with your family while we take care of delivering delicious food straight to your doorstep, making your gathering a truly unforgettable experience. ",

    imageRight: birthday,
    Gal1: [galleryf1, galleryf2, galleryf3, galleryf4],
    Menu: [
      // {
      //   Header: "APPETIZER",
      //   Slugs: "/appetizer",
      //   iconfood: samosa,
      //   ServicesHeading1: [],
      // },

      {
        Header: "CURRY",
        subTitle: "(prices are per tray)",
        Slugs: "/curry",
        iconfood: curry,
        ServicesHeading1: [
          {
            title: "POTATOMASALA",
            price: "$40.00",
            desc: "",
          },
          {
            title: "VEG JALFREZI",
            price: "$45.00",
            desc: "",
          },

          {
            title: "PannerBhurji",
            price: "$55.00",
            desc: "",
          },

          {
            title: "Korma curry",
            price: "$45.00",
            desc: "",
          },

          {
            title: "Hyderabadi Curry",
            price: "$45.00",
            desc: "",
          },

          {
            title: "Dal Makhni",
            price: "$45.00",
            desc: "",
          },

          {
            title: "Channa tendli",
            price: "$45.00",
            desc: "",
          },

          {
            title: "Dahi Curry",
            price: "$45.00",
            desc: "",
          },

          {
            title: "Eggplant curry",
            price: "$45.00",
            desc: "",
          },

          {
            title: "Palak Paneer",
            price: "$55.00",
            desc: "",
          },

          {
            title: "SAAG PANEER",
            price: "$55.00",
            desc: "",
          },

          {
            title: "MUTTER PANEER",
            price: "$55.00",
            desc: "",
          },

          {
            title: "ALOO SAAG",
            price: "$45.00",
            desc: "",
          },

          {
            title: "CHANA MASALA",
            price: "$45.00",
            desc: "",
          },

          {
            title: "ALOO MUTTER",
            price: "$45.00",
            desc: "",
          },

          {
            title: "SPECIAL VEGETABLE CURRY",
            price: "$45.00",
            desc: "",
          },

          {
            title: "YELLOW DAL",
            price: "$45.00",
            desc: "",
          },

          {
            title: "ALOO GOBI",
            price: "$45.00",
            desc: "",
          },

          {
            title: "KADAI BHINDI",
            price: "$45.00",
            desc: "",
          },

          {
            title: "VEGETABLE MAKHNI",
            price: "$45.00",
            desc: "",
          },

          {
            title: "PANEER MAKHNI",
            price: "$55.00",
            desc: "",
          },
        ],
      },

      {
        Header: "Rice",
        subTitle: "(prices are per tray)",
        Slugs: "/rice",
        iconfood: rice,
        ServicesHeading1: [
          {
            title: "Tamrind Rice",
            price: "$45.00",
            desc: "",
          },
          {
            title: "Steamed rice",
            price: "$30.00",
            desc: "",
          },

          {
            title: "Tomato Rice",
            price: "$35.00",
            desc: "",
          },

          {
            title: "Jeera/Peas Pulao",
            price: "$35.00",
            desc: "",
          },
          {
            title: "BISI BELE BATH",
            price: "$45.00",
            desc: "",
          },

          {
            title: "LEMON RICE",
            price: "$45.00",
            desc: "",
          },

          {
            title: "BAGALA BATH (Served Cold)",
            price: "$45.00",
            desc: "",
          },

          {
            title: "PONGAL",
            price: "$45.00",
            desc: "",
          },

          {
            title: "UPMA",
            price: "$35.00",
            desc: "",
          },

          {
            title: "VEGETABLE BPULAO",
            price: "$45.00",
            desc: "",
          },
        ],
      },
      {
        Header: "Starters",
        subTitle: "(prices are per tray)",
        Slugs: "/starters",
        iconfood: samosa,
        ServicesHeading1: [
          {
            title: "Idli + Sambhar + Chutney’s",
            price: "$30.00 (per dozen)",
            desc: "",
          },

          {
            title: "Medu Vada + Sambhar + Chutney’s ",
            price: "$30.00 (per dozen)",
            desc: "",
          },

          {
            title: "Batata vada + Chutney’s ",
            price: "$30.00 (per dozen)",
            desc: "",
          },

          {
            title: "Samosa + Chutney’s ",
            price: "$30.00 (per dozen)",
            desc: "",
          },

          {
            title: "Assorted Pakoras + Chutney’s ",
            price: "$30.00 (two dozen)",
            desc: "",
          },

          {
            title: "Onion Pakoras + Chutney’s ",
            price: "$30.00 (two dozen)",
            desc: "",
          },
        ],
      },

      {
        Header: "Breads",
        subTitle: "(prices are per tray)",
        Slugs: "/bread",
        iconfood: bread,
        ServicesHeading1: [
          {
            title: "Poori",
            price: "$24.00 (per dozen)",
            desc: "",
          },
          {
            title: "Chapati",
            price: "$10.00 (per dozen)",
            desc: "",
          },
          {
            title: "Batura",
            price: "$30.00 (per dozen)",
            desc: "",
          },
        ],
      },

      {
        Header: "Deserts",
        subTitle: "(prices are per tray)",
        Slugs: "/seserts",
        iconfood: dessert,
        ServicesHeading1: [
          {
            title: "Rava Kesari",
            price: "$50.00",
            desc: "",
          },

          {
            title: "Gajjar Halwa",
            price: "$50.00",
            desc: "",
          },

          {
            title: "Dal PVorne",
            price: "$50.00",
            desc: "",
          },

          {
            title: "VermSago Payasam",
            price: "$50.00",
            desc: "",
          },

          {
            title: "Rice Pudding",
            price: "$50.00",
            desc: "",
          },
        ],
      },
    ],
  },
  {
    id: 3,
    slug: "/catering",
    h1: "Catering",
    greet: "Welcome To Birthday",
    Header: "Catering",

    seoPara:
      "The Udupi Palace brings the authentic flavors of Indian vegetarian cuisine straight to your event with our comprehensive catering services. Whether you're planning an intimate gathering or a grand celebration, we cater to all occasions, big or small. Our catering packages are designed to elevate your event experience, featuring live dosa and chaat counters where skilled chefs craft mouthwatering delicacies right before your guests' eyes.  Packages start from $15 to $35 for a minimum of 50 to 100 Pax.From crispy dosas filled with flavorful fillings to tangy and spicy chaats that tantalize the taste buds, our live counters add an interactive element to your event, ensuring an unforgettable dining experience for all. Additionally, our catering services include a delectable array of starters to kickstart the festivities and irresistible desserts to sweeten the occasion. With Udupi Palace catering, every detail is meticulously planned and executed to perfection, allowing you to relax and enjoy your event while we take care of the culinary delights.",
    imageRight: birthday,
    Gal1: [
      categ1,
      categ2,
      categ3,
      categ4,
      categ5,
      categ6,
      categ7,
      categ8,
      categ9,
    ],
    Menu: [
      {
        Header: "Package 1",
        Slugs: "/package1",

        iconfood: samosa,
        ServicesHeading1: [
          {
            title: "2 snacks , Dosa counter live x 4 types, & Desert",
            price: "$25 pp",
            desc: "",
          },
        ],
      },
      {
        Header: "Package 2",
        Slugs: "/package2",

        iconfood: samosa,
        ServicesHeading1: [
          {
            title: "3 snacks, dosa live, Dessert, & chaat counter ",
            price: "$ 30 pp",
            desc: "",
          },
        ],
      },
      {
        Header: "Package 3",
        Slugs: "/package3",

        iconfood: samosa,
        ServicesHeading1: [
          {
            title: "4 snacks, dosa live, desert x 2 types, spl rice",
            price: "$ 30 pp",
            desc: "",
          },
        ],
      },
    ],
  },
  {
    id: 4,
    slug: "/event-hall",
    h1: "Event Hall",
    greet: "Welcome To corporate",
    Header: "Event Hall",
    Content:
      "Welcome to our Wynd event , a top event organisers in Andheri ,where the essence of corporate sophistication converges with seamless event execution. As a premier venue for corporate events, top event organisers in Andheri offer a setting that transcends expectations, blending functionality with refined aesthetics.Our banquet hall, with a capacity for hosting corporate gatherings, conferences, and seminars, stands as a beacon of professionalism. From high-profile meetings to product launches, our venue provides a versatile canvas tailored to meet the unique demands of corporate events.Immerse yourself in an environment where state-of-the-art facilities harmonize with tasteful decor. Top event organisers in Andheri, Our commitment to excellence is evident in every detail, ensuring a conducive atmosphere for productive discussions and networking opportunities.",
    seoPara:
      "Located in the heart of Canada, Udupi Palace invites you to indulge in the vibrant flavors of Indian vegetarian cuisine within the intimate ambiance of our basement event space. With a capacity of up to 30 to 35 guests, our restaurant basement provides the perfect setting for a variety of events, from family gatherings to corporate dinners and everything in between. Whether you're celebrating a milestone birthday, hosting a business luncheon, or simply seeking a cozy atmosphere to enjoy delicious food with friends, our dedicated team ensures that every detail is taken care of, leaving you free to relax and savor the moment. Immerse yourself in the rich aromas and spices of traditional Indian dishes while surrounded by the rustic charm of our basement venue. With ample space for mingling and comfortable seating arrangements, your guests are sure to feel welcomed and at ease throughout the duration of your event. From savory appetizers to mouthwatering entrees and decadent desserts, our menu offers a diverse selection of vegetarian delights to tantalize your taste buds. Whether you choose to customize a set menu or opt for our renowned buffet-style service, our chefs are committed to delivering an unforgettable culinary experience that reflects the essence of Indian cuisine. Come discover the magic of  The Udupi Palace's basement event space and create memories that will last a lifetime. ",
    imageRight: corporate1,
    Gal1: [gallery1, gallery2, gallery3, gallery4, gallery5, gallery6],
    Menu: [],
  },
  {
    id: 5,
    slug: "/tiffin-service",
    h1: "Tiffin Service",
    greet: "Welcome To Wedding",
    Header: "Tiffin Service",
    Content:
      "Step into a world of timeless romance and unparalleled elegance as we welcome you to our Wynd event , a top event organisers in Andheri – the epitome of dream weddings. With a capacity to host the most magical celebrations, our venue is a canvas where love stories unfold in grandeur and sophistication.At the heart of our commitment is the belief that weddings are not just events; they are the culmination of dreams and the beginning of beautiful journeys. Our banquet hall, meticulously designed for such momentous occasions, is a testament to the perfect blend of opulence and intimate charm.As you embark on your journey through our venue, discover the artistry in every detail – from the ethereal decor to the spacious interiors that can accommodate your closest loved ones. With a capacity to host large gatherings, top event organisers in Andheri cater to the grandest celebrations, ensuring that each moment is infused with grace and joy.",
    seoPara:
      "Our tiffin services at Udupi Palace have garnered rave reviews from both corporates and individuals alike. Perfect for those seeking a convenient and delicious meal option, our tiffins are a big hit among busy professionals and families. Catering to both corporate offices and individual customers, our tiffin services are available with a minimum order requirement of just 5 pax, ensuring flexibility and accessibility for all. With a diverse menu featuring a variety of Indian vegetarian delights, our tiffin offerings are crafted with care and attention to detail, guaranteeing a delightful dining experience every time. Whether you're looking for a quick lunch solution for your office or a hassle-free meal option for your daily needs, Udupi Palace's tiffin services deliver quality and flavor right to your doorstep, making every meal a memorable one.",
    imageRight: wedding2,
    Gal1: [galleryt1, galleryt2, galleryt3, galleryt4, galleryt5, galleryt6],
    Menu: [],
  },
];
export default EventData;
