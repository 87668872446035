import React, {  useLayoutEffect } from 'react'
import banquet1 from '../../assets/room3.jpeg'
import Footer from '../Footer/Footer'
import BannerA from "../BannerAbout/BannerA";
import Welcome from '../Welcome/Welcome'
import BanquetGallery from '../BanquetGallery/BanquetGallery'
import { useLocation } from 'react-router-dom'
import banquentData from "./JsonBanquet2"
import BanquetGallary from '../BanquetGallery/BanquetGallary'
import WelcomeBanquet from '../WelcomeBanquet/WelcomeBanquet'

function Banquet() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});
  // const search = useLocation()
  // const path = search.pathname;
  // const [serviceData,setServiceData]= useState(banquentData)
  // console.log(path)
  // const EventsF =  serviceData.find((e)=> e.slug === path)
  //  console.log(EventsF)
   
  return (
  
    <>
   <div className="breadcrubs ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcurbs-inner text-center">
                            <h3 className="breadcrubs-title">
                               Events
                            </h3>
                            <ul>
                                <li><a href="index.html">home <span>//</span>  </a></li>
                                <li>Events</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <WelcomeBanquet />
    {/* <Welcome/> */}
    <section id="facilitiesAmenities" className="pb-10">
  <div className="container">
    <div
      className="mb-10 text-center"
      data-cue="fadeIn"
      data-show="true"
      style={{
        animationName: "fadeIn",
        animationDuration: "1000ms",
        animationTimingFunction: "ease",
        animationDelay: "0ms",
        animationDirection: "normal",
        animationFillMode: "both"
      }}
    >
       <div className="section-title white_bg mb-50 text-center">
            <h2>Gallery view</h2>
            {/* <p>
              {" "}
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim nostrud exercitation ullamco laboris nisi.
            </p> */}
          </div>
    </div>

   
    <BanquetGallary/>
    
    
  </div>
</section>
<Footer/>
  
    </>
  // ))
  )
}

export default Banquet
