import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "./Banner.css";
import Popmodal from "../Popmodal";
import { Link, NavLink } from "react-router-dom";
import Typed from "typed.js";
import { Pagination, Navigation, EffectFade, Autoplay } from "swiper/modules";
import swipperImg1 from "../../assets/img12.webp";
import swipperImg2 from "../../assets/jpeg-optimizer_img5.webp";
import swipperImg3 from "../../assets/jpeg-optimizer_img9.webp";
import banner1 from "../../assets/imageWebp/wynd(5).webp";
import banner2 from "../../assets/imageWebp/wynd(10).webp";
import banner3 from "../../assets/imageWebp/wynd(4).webp";
import "../Service/Service.css";
const Banner = () => {
  useEffect(() => {
    const options = {
      strings: [
        "The Udupi Palace",
        "Indian veg. cuisine",
        "Jain Food Available",
        "Take Out",
        "Dine In",
        "Delivery",
      ],
      typeSpeed: 100,
      backSpeed: 40,
      loop: true,
    };

    const typing = new Typed(".text", options);

    return () => {
      typing.destroy();
    };
  }, []);

  return (
    <>
      {/* <div
  className="item bannerIMAGES1 image-banner"
  style={{
    backgroundImage: `url(${banner1})`
  }}
> */}

      <Swiper
        effect={"fade"}
        navigation={true}
        loop={true}
        autoplay={{
          delay: 20000,
          disableOnInteraction: false,
        }}
        modules={[EffectFade, Navigation, Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="slider-area">
            <div
              id="firstSlide"
              className="pogoSlider pogoSlider--dirCenterHorizontal pogoSlider--navBottom"
            >
              <div
                className="pogoSlider-slide"
                data-transition="fade"
                data-duration={1500}
                style={{
                  background:
                    'url("img/slider/slide_1.jpg") 0px 0px / cover no-repeat scroll',
                  opacity: 1,
                  transitionDuration: "1500ms",
                }}
              >
                <div className="pogoSlider-progressBar">
                  <div
                    className="pogoSlider-progressBar-duration"
                    style={{ width: 0 }}
                  />
                </div>
                <h2 className="pogoSlider-slide-element">Welcome to</h2>
                <h1 className="pogoSlider-slide-element">
                  <span className="text"></span>
                </h1>
                <h3 className="pogoSlider-slide-element">
                  <NavLink to="/contact-us" data-toggle="modal">
                    Reservation Now
                  </NavLink>
                </h3>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider-area">
            <div
              id="firstSlide"
              className="pogoSlider pogoSlider--dirCenterHorizontal pogoSlider--navBottom"
            >
              <div
                className="pogoSlider-slide"
                data-transition="fade"
                data-duration={1500}
                style={{
                  background:
                    'url("https://i0.wp.com/swadishta.de/wp-content/uploads/2021/01/Idle.jpg?fit=1200%2C800&ssl=1g") 0px 0px / cover no-repeat scroll',
                  opacity: 1,
                  transitionDuration: "1500ms",
                }}
              >
                <div className="pogoSlider-progressBar">
                  <div
                    className="pogoSlider-progressBar-duration"
                    style={{ width: 0 }}
                  />
                </div>
                <h2 className="pogoSlider-slide-element">Food In Trays</h2>
                <h1 className="pogoSlider-slide-element">
                  <span
                    className="textSecond"
                    style={{ fontSize: "1.2rem", textTransform: "capitalize" }}
                  >
                    Food In Trays available at 1 day notice
                  </span>
                </h1>
                <h3 className="pogoSlider-slide-element">
                  <NavLink to="/food-intrays" data-toggle="modal">
                    Check Menu
                  </NavLink>
                </h3>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider-area">
            <div
              id="firstSlide"
              className="pogoSlider pogoSlider--dirCenterHorizontal pogoSlider--navBottom"
            >
              <div
                className="pogoSlider-slide"
                data-transition="fade"
                data-duration={1500}
                style={{
                  background:
                    'url("https://5.imimg.com/data5/SS/RD/MY-47153836/catering-services.jpg") 0px 0px / cover no-repeat scroll',
                  opacity: 1,
                  transitionDuration: "1500ms",
                }}
              >
                <div className="pogoSlider-progressBar">
                  <div
                    className="pogoSlider-progressBar-duration"
                    style={{ width: 0 }}
                  />
                </div>
                <h2 className="pogoSlider-slide-element">Catering</h2>
                <h1 className="pogoSlider-slide-element">
                  <span
                    className="textSecond"
                    style={{ fontSize: "1.2rem", textTransform: "capitalize" }}
                  >
                    Authentic Indian Vegetarian Catering for Your Event !
                  </span>
                </h1>
                <h3 className="pogoSlider-slide-element">
                  <NavLink to="/catering" data-toggle="modal">
                    View Packages
                  </NavLink>
                </h3>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider-area">
            <div
              id="firstSlide"
              className="pogoSlider pogoSlider--dirCenterHorizontal pogoSlider--navBottom"
            >
              <div
                className="pogoSlider-slide"
                data-transition="fade"
                data-duration={1500}
                style={{
                  background:
                    'url("https://www.maasbest.com/images/pages/home/11.jpg") 0px 0px / cover no-repeat scroll',
                  opacity: 1,
                  transitionDuration: "1500ms",
                }}
              >
                <div className="pogoSlider-progressBar">
                  <div
                    className="pogoSlider-progressBar-duration"
                    style={{ width: 0 }}
                  />
                </div>
                <h2 className="pogoSlider-slide-element">Tiffin Service</h2>
                <h1 className="pogoSlider-slide-element">
                  <span
                    className="textSecond"
                    style={{ fontSize: "1.2rem", textTransform: "capitalize" }}
                  >
                    Savor convenience, savor flavor – our tiffins redefine
                    mealtime bliss!
                  </span>
                </h1>
                <h3 className="pogoSlider-slide-element">
                  <NavLink to="/tiffin-service" data-toggle="modal">
                    View Packages
                  </NavLink>
                </h3>
              </div>
            </div>
          </div>
        </SwiperSlide>
        {/* <SwiperSlide>
  <div className="imagee slider-banner first-banner"
   style={{
    backgroundImage: `url(${banner1})`,
  }}
  >
    <div className="overlay-black">
    <div className="container sliderouter">
      <div className="bannerhead">
        <div className="slider-item-content">
          <h1 className="textcenter colorWhi">
          Elegance Redefined: Your Perfect Celebration Awaits
          </h1>
          <p className="textcenter colorWhi">
          Craft memories in venues echoing timeless elegance and everlasting allure.    </p>
          <div className="textcenter">
            <button
              role="button"
              tabIndex={0}
              href="#!"
              className="custom-btn "
            >
              <a
                href=""
                style={{ color: "white" }}
              >
                <Link to="Gallery">
                View More
                </Link>
              
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  </div>
  </SwiperSlide> */}

        {/* <SwiperSlide> 
          <div className="swiper2 imagee slider-banner second-banner"
        
          >
           <div className="overlay-black">
    <div className="container sliderouter">
      <div className="bannerhead">
        <div className="slider-item-content">
          <h1 className="textcenter colorWhi">
          From Dreams to Reality: Transforming Events in Our Opulent Banquet Hall.          </h1>
          <p className="textcenter colorWhi">
          Witness spaces evolve into personalized dreamscapes with seamless creativity.
          </p>
          <div className="textcenter">
            <button
              role="button"
              tabIndex={0}
              href="#!"
              className="custom-btn "
            >
              <a
                href="/wedding-event-planner-mumbai"
                style={{ color: "white" }}
              >
                <Link to='Gallery'>
                View More
                </Link>
              
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
         </div>
          </div>
  </SwiperSlide>

        <SwiperSlide>
          <div className="swiper2 imagee slider-banner third-banner"
          // style={{
          //   backgroundImage: `url(${banner3})`
          // }}
          
          >
          <div className="overlay-black">
    <div className="container sliderouter">
      <div className="bannerhead">
        <div className="slider-item-content">
          <h1 className="textcenter colorWhi">
          Beyond Expectations: Your Extraordinary Moments Begin in Our Banquet Hall
                    </h1>
          <p className="textcenter colorWhi">
            Event management Crafting seamless, unforgettable experiences for
            you
          </p>
          <div className="textcenter">
            <button
              role="button"
              tabIndex={0}
              href="#!"
              className="custom-btn "
            >
              <a
                href="/wedding-event-planner-mumbai"
                style={{ color: "white" }}
              >
                 <Link to="Gallery">
                 View More
                </Link>
             
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
          </div>
          </div>
         
  </SwiperSlide> */}
      </Swiper>
      {/* </div> */}
      {/* <Popmodal/> */}
    </>
  );
};

export default Banner;
