import React,{ useLayoutEffect} from 'react'
import "./About.css"

import Welcome from '../Welcome/Welcome'
import Testimonial2 from '../Testimonial/Testimonial2'
import Footer from '../Footer/Footer'
import BannerA from "../BannerAbout/BannerA";

import aboutImg from '../../assets/christian-wedding-in-kerala.jpg'
import WelcomeAboutUs from '../WelcomeAboutUs/WelcomeAboutUs'
function About() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});
  return (
    <>
  
      <BannerA/>
<WelcomeAboutUs />
{/* <Welcome/> */}

<Testimonial2/>
<Footer/>
    </>
  )
}

export default About
