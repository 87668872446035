import React, { useLayoutEffect } from "react";
import "../Footer/Footer.css";
import Popmodal from "../Popmodal";
import { Link } from "react-router-dom";
const Footer = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <footer className="footer-section">
        <div className="container original-height">
          <div className="cat-section-container">
            <div className="cat-slide-section">
              <div className="cat-footer-info">
                <div className="cat-footer-logo">
                  <img src="../assets/images/informative/logo.png" alt="" />
                  <p>
                    Established in 2001, The Udupi Palace stands as the
                    pioneering vegetarian eatery in Toronto, introducing
                    authentic South Indian flavors to the city.
                  </p>
                </div>
                <div className="cat-footer-social">
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/udupipalacetoronto">
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 155.139 155.139"
                          xmlSpace="preserve"
                        >
                          <g>
                            <path
                              id="f_1_"
                              d="M89.584,155.139V84.378h23.742l3.562-27.585H89.584V39.184
                                                                          c0-7.984,2.208-13.425,13.67-13.425l14.595-0.006V1.08C115.325,0.752,106.661,0,96.577,0C75.52,0,61.104,12.853,61.104,36.452
                                                                          v20.341H37.29v27.585h23.814v70.761H89.584z"
                            />
                          </g>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/udupipalacetoronto/">
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 512 512"
                          xmlSpace="preserve"
                        >
                          <g>
                            <g>
                              <path
                                d="M352,0H160C71.648,0,0,71.648,0,160v192c0,88.352,71.648,160,160,160h192c88.352,0,160-71.648,160-160V160
                                                                              C512,71.648,440.352,0,352,0z M464,352c0,61.76-50.24,112-112,112H160c-61.76,0-112-50.24-112-112V160C48,98.24,98.24,48,160,48
                                                                              h192c61.76,0,112,50.24,112,112V352z"
                              />
                            </g>
                          </g>
                          <g>
                            <g>
                              <path
                                d="M256,128c-70.688,0-128,57.312-128,128s57.312,128,128,128s128-57.312,128-128S326.688,128,256,128z M256,336
                                                                              c-44.096,0-80-35.904-80-80c0-44.128,35.904-80,80-80s80,35.872,80,80C336,300.096,300.096,336,256,336z"
                              />
                            </g>
                          </g>
                          <g>
                            <g>
                              <circle cx="393.6" cy="118.4" r="17.056" />
                            </g>
                          </g>
                        </svg>
                      </a>
                    </li>
                    {/* <li>
              <a href="javascript:void(0);" target="_blank">
                <svg
                  viewBox="-21 -35 682.66669 682"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m77.613281-.667969c-46.929687 0-77.613281 30.816407-77.613281 71.320313 0 39.609375 29.769531 71.304687 75.8125 71.304687h.890625c47.847656 0 77.625-31.695312 77.625-71.304687-.894531-40.503906-29.777344-71.320313-76.714844-71.320313zm0 0" />
                  <path d="m8.109375 198.3125h137.195313v412.757812h-137.195313zm0 0" />
                  <path d="m482.054688 188.625c-74.011719 0-123.640626 69.546875-123.640626 69.546875v-59.859375h-137.199218v412.757812h137.191406v-230.5c0-12.339843.894531-24.660156 4.519531-33.484374 9.917969-24.640626 32.488281-50.167969 70.390625-50.167969 49.644532 0 69.5 37.851562 69.5 93.339843v220.8125h137.183594v-236.667968c0-126.78125-67.6875-185.777344-157.945312-185.777344zm0 0" />
                </svg>
              </a>
            </li>
            <li>
              <a href="javascript:void(0);" target="_blank">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512 512"
                  xmlSpace="preserve"
                >
                  <g>
                    <g>
                      <path
                        d="M512,97.248c-19.04,8.352-39.328,13.888-60.48,16.576c21.76-12.992,38.368-33.408,46.176-58.016
                                                                              c-20.288,12.096-42.688,20.64-66.56,25.408C411.872,60.704,384.416,48,354.464,48c-58.112,0-104.896,47.168-104.896,104.992
                                                                              c0,8.32,0.704,16.32,2.432,23.936c-87.264-4.256-164.48-46.08-216.352-109.792c-9.056,15.712-14.368,33.696-14.368,53.056
                                                                              c0,36.352,18.72,68.576,46.624,87.232c-16.864-0.32-33.408-5.216-47.424-12.928c0,0.32,0,0.736,0,1.152
                                                                              c0,51.008,36.384,93.376,84.096,103.136c-8.544,2.336-17.856,3.456-27.52,3.456c-6.72,0-13.504-0.384-19.872-1.792
                                                                              c13.6,41.568,52.192,72.128,98.08,73.12c-35.712,27.936-81.056,44.768-130.144,44.768c-8.608,0-16.864-0.384-25.12-1.44
                                                                              C46.496,446.88,101.6,464,161.024,464c193.152,0,298.752-160,298.752-298.688c0-4.64-0.16-9.12-0.384-13.568
                                                                              C480.224,136.96,497.728,118.496,512,97.248z"
                      />
                    </g>
                  </g>
                </svg>
              </a>
            </li> */}
                  </ul>
                </div>
                <div className="cat-copyright-area">
                  {/* <p>Copyright © 2021 Catering . All rights reserved.</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright-area">
          <div className="container">
            <div className="">
              <div className="">
                <div className="copyright-text  justify-content-space-between">
                  <p>
                    Copyright © 2024, All Right Reserved <br />
                    <a href="">Udupi Palace</a>
                  </p>
                  <p>
                    Developed By &nbsp;
                    <a href="https://skdm.in/">
                      Shree Krishna Digital Marketing
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="floattingButton">
        {/* <a href="#" className="float" id="menu-share">
    <i className="fa fa-link my-float" />
  </a> */}
        {/* <ul>
    <li>
      <a href="https://www.instagram.com/wyndeventplanner/" target='_blank'>
        <i className="fa fa-instagram my-float" />
      </a>
    </li>
    <li>
      <a href="https://www.facebook.com/wyndeventplanner" target='_blank'>
        <i className="fa fa-facebook my-float" />
      </a>
    </li>
  </ul> */}
      </div>
    </div>
  );
};

export default Footer;
