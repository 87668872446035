import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import poster from "../assets/WhatsApp Image 2024-11-14 at 19.08.44.jpeg";

const Popmodal = () => {
  const [show, setShowModal] = useState(true);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setShowModal(true);
  //   }, 2000);

  //   return () => clearTimeout(timer);
  // }, []);
  const handleClose = () => setShowModal(false);
  return (
    <Modal show={show} onHide={handleClose}>
      {/* <Modal.Header closeButton>
      <Modal.Title>UDUPI PALACE</Modal.Title>
    </Modal.Header> */}
      <Modal.Body>
        <div className="close" closeButton></div>
        <div className="popImg">
          <img src={poster} alt="" style={{ width: "100%", Height: "100%" }} />
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
      <Button variant="secondary" onClick={() => setShowModal(false)}>
        Close
      </Button>
      <Button variant="primary" >
        Save Changes
      </Button>
    </Modal.Footer> */}
    </Modal>
  );
};

export default Popmodal;
