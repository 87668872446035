import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination, Autoplay } from "swiper/modules";
import "./Testimonial2.css";
const Testimonial2 = () => {
  return (
    <div>
      <div className="testimonial ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="section-title white_bg mb-50 text-center">
                <h2 className="mb-50">Our Client Loves</h2>
                <p>
                  {" "}
                  The Udupi Restaurant is the most authentic South Indian restaurant. No other restaurant can match up to its taste and hospitality. We have been going there for the last 10 years and we would continue to go there, because IT IS THE BEST!

​
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              {/* <div className="testimonial-sliders">
                
                <div className="row">
                  <div className="col-lg-10 offset-lg-1"> */}
                    <div
                      className="testimonial-text-slider text-center  slick-initialized slick-slider slick-dotted"
                      role="toolbar"
                    >
                      <div aria-live="polite" className="slick-list">
                        {/* <div
                          className="slick-track"
                          role="listbox"
                          style={{ opacity: 1, width: 2349 }}
                        > */}
                      <Swiper
              // navigation={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, Pagination]}
              breakpoints={{
                570: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 50,
                },
                768: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 1,
                  spaceBetween: 70,
                },
              }}
              pagination={{
                clickable: true,
              }}
           
              className="mySwiper1"
            >
                            <SwiperSlide>
                              <div
                                className="single-test-text slick-slide slick-current slick-active"
                                data-slick-index={0}
                                aria-hidden="false"
                                tabIndex={-1}
                                role="option"
                                aria-describedby="slick-slide10"
                                // style={{
                                //   width: 783,
                                //   position: "relative",
                                //   left: 0,
                                //   top: 0,
                                //   zIndex: 999,
                                //   opacity: 1,
                                // }}
                              >
                                <p className="text-qoute">
                                  <i
                                    className="fa fa-quote-left"
                                    aria-hidden="true"
                                  />
                                </p>
                                <p className="test-text">
                                Really good brunch buffet. We were visiting from calgary and loved the food. Really happy to see goli bajji in the menu. Reminded us of restaurants back home. Service was great. They came in with hot idlis, puris and dosas to our table.

​
                                </p>
                                {/* <div className="test-title mb-50">
                                  <h4>Alexandra</h4>
                                  <p>Manager</p>
                                </div> */}
                              </div>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div
                                className="single-test-text slick-slide slick-current slick-active"
                                data-slick-index={0}
                                aria-hidden="false"
                                tabIndex={-1}
                                role="option"
                                aria-describedby="slick-slide10"
                                // style={{
                                //   width: 783,
                                //   position: "relative",
                                //   left: 0,
                                //   top: 0,
                                //   zIndex: 999,
                                //   opacity: 1,
                                // }}
                              >
                                <p className="text-qoute">
                                  <i
                                    className="fa fa-quote-left"
                                    aria-hidden="true"
                                  />
                                </p>
                                <p className="test-text">
                                Loved the experience. This place is a hidden gem and far superior to other better known / more expensive joints for dosa and South Indian food. Great service and food options. The starter platter with assorted snacks was a treat as were the dosas. Will keep coming back.
                                </p>
                                {/* <div className="test-title mb-50">
                                  <h4>Alexandra</h4>
                                  <p>Manager</p>
                                </div> */}
                              </div>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div
                                className="single-test-text slick-slide slick-current slick-active"
                                data-slick-index={0}
                                aria-hidden="false"
                                tabIndex={-1}
                                role="option"
                                aria-describedby="slick-slide10"
                                // style={{
                                //   width: 783,
                                //   position: "relative",
                                //   left: 0,
                                //   top: 0,
                                //   zIndex: 999,
                                //   opacity: 1,
                                // }}
                              >
                                <p className="text-qoute">
                                  <i
                                    className="fa fa-quote-left"
                                    aria-hidden="true"
                                  />
                                </p>
                                <p className="test-text">
                                Great place for South Indian foods. Staffs are very caring, all types of dosas, Vadas, idlis, puris etc are served. Will definitely make more visits and recommend to anyone who wants to enjoy medium spicy South Indian food.
                                </p>
                                {/* <div className="test-title mb-50">
                                  <h4>Alexandra</h4>
                                  <p>Manager</p>
                                </div> */}
                              </div>
                            </SwiperSlide>
                         
                          </Swiper>
                         
                        {/* </div> */}
                      </div>
                      {/* <ul className="slick-dots" style={{}} role="tablist">
                        <li
                          className="slick-active"
                          aria-hidden="false"
                          role="presentation"
                          aria-selected="true"
                          aria-controls="navigation10"
                          id="slick-slide10"
                        >
                          <a href="javascript:void(0)">1</a>
                        </li>
                        <li
                          aria-hidden="true"
                          role="presentation"
                          aria-selected="false"
                          aria-controls="navigation11"
                          id="slick-slide11"
                        >
                          <a href="javascript:void(0)">2</a>
                        </li>
                        <li
                          aria-hidden="true"
                          role="presentation"
                          aria-selected="false"
                          aria-controls="navigation12"
                          id="slick-slide12"
                        >
                          <a href="javascript:void(0)">3</a>
                        </li>
                      </ul> */}
                    </div>
                  {/* </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial2;
