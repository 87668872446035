import React, { useState, useLayoutEffect } from 'react';
import { Image } from 'antd';
import bday from "../images/birtthday.jpg"
import baby from "../images/baby shower.jpg"
import bach from "../images/bachelors.png"
import get from "../images/getogether.jpg"
import kitty from "../images/kitty.jpg"
import anniv from "../images/anniversary.jpg"
import "./Gallery.css"

import GalleryData from '../Components/GalleryCom/GalleryData';
import Banquetgallery from '../Components/BanquetGallery/BanquetGallery';
import BannerA from '../Components/BannerAbout/BannerA';
import Footer from '../Components/Footer/Footer'

const Gallery = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

   

    const [data, setData] = useState(GalleryData)

    // Store Category in CategoryData 
    const categoryData = GalleryData.map((value) => {
        return value.Category
    });
    ////////////

    const tabsData = ["all", ...new Set(categoryData)];


    // for all categoryData set 
    const filterCategory = (Category) => {
        if (Category == "all") {
            setData(GalleryData)
            return;
        }
        const filteredData = GalleryData.filter((value) => {
            return value.Category == Category;
        })

        setData(filteredData);

    }


    return (
        <>
        <div className="breadcrubs ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcurbs-inner text-center">
                            <h3 className="breadcrubs-title">
                               EVENTS
                            </h3>
                            <ul>
                                <li><a href="index.html">home <span>//</span>  </a></li>
                                <li>EVENTS</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <GalleryPoster/> */}
            {/* /////////////////////////////// section 1 /////////////////////////////////// */}
            < section className='GallerySection1' >
          
            </section > 
            {/* /////////////////////////// Section 2 /////////////////////////////////////////// */}

            <section className='Image-Gallery'>
               
                <div className='container'>
                    {/* <p className='text-center'>
                    Welcome to our gallery, a visual journey through the epitome of elegance and grandeur. We are your social event organisers in Andheri, with a capacity to host 2000 guests, unfolds as a captivating tapestry of opulence and thoughtful design.
                    As you explore our gallery, witness the seamless fusion of sophistication and functionality. Each image encapsulates the essence of our venue, a space meticulously crafted to provide a canvas for unforgettable events. From enchanting weddings to corporate galas, our social event organisers in Andheri stand as versatile arenas for diverse celebrations.

                    </p>
                    <p className='d-none'>
                    Immerse yourself in the curated visuals showcasing the spacious interiors adorned with tasteful decor. The ambiance, meticulously designed to cater to various themes, reflects the commitment to hosting events that transcend expectations.
Our gallery is a testament to our dedication to detail – from the intricacies of table settings to the immersive lighting that enhances the atmosphere. Cutting-edge facilities and advanced audiovisual technology are seamlessly integrated into the space, ensuring a seamless and memorable experience for every guest.

At the heart of our gallery is a celebration of diversity, where each event is unique, yet every moment radiates excellence. Join our social event organisers in Andheri for a visual odyssey, where the grandeur of a 2000-guest capacity banquet hall is captured in every frame. As you navigate through our gallery, envision your special moments taking center stage in this splendid setting.

Welcome to an immersive experience, and meet our social event organisers in Andheri,  where every photograph tells a story of luxury, sophistication, and the promise of unforgettable celebrations. Explore our gallery and let the visuals transport you to a world where your events become extraordinary masterpieces.

                    </p> */}
                    <div className='row'>
                  
                    <div style={{paddingTop:"20px"}}>
<div className="container">

          <div id="image-gallery" className="row">
      
            <a
              className="col-md-4 "
              style={{ paddingBottom: "20px" }}
              // src={img1}
              data-lg-size="1600-2400"
              // data-lg-src={img1}
            >
              <img
               src={bday}
                style={{ height: "300px", width: "100%" }}
                alt="Enquery"
              />
            </a>
            <a
              className="col-md-4 "
              style={{ paddingBottom: "20px" }}
              // src={img1}
              data-lg-size="1600-2400"
              // data-lg-src={img1}
            >
              <img
               src={anniv}
                style={{ height: "300px", width: "100%" }}
                alt="Enquery"
              />
            </a>
            <a
              className="col-md-4 "
              style={{ paddingBottom: "20px" }}
              // src={img1}
              data-lg-size="1600-2400"
              // data-lg-src={img1}
            >
              <img
               src={baby}
                style={{ height: "300px", width: "100%" }}
                alt="Enquery"
              />
            </a>
            <a
              className="col-md-4 "
              style={{ paddingBottom: "20px" }}
              // src={img1}
              data-lg-size="1600-2400"
              // data-lg-src={img1}
            >
              <img
               src={kitty}
                style={{ height: "300px", width: "100%" }}
                alt="Enquery"
              />
            </a>
            <a
              className="col-md-4 "
              style={{ paddingBottom: "20px" }}
              // src={img1}
              data-lg-size="1600-2400"
              // data-lg-src={img1}
            >
              <img
               src={bach}
                style={{ height: "300px", width: "100%" }}
                alt="Enquery"
              />
            </a>
            <a
              className="col-md-4 "
              style={{ paddingBottom: "20px" }}
              // src={img1}
              data-lg-size="1600-2400"
              // data-lg-src={img1}
            >
              <img
               src={get}
                style={{ height: "300px", width: "100%" }}
                alt="Enquery"
              />
            </a>
          </div>
        </div>
</div>
      
                      
                    </div>
                </div>
            </section>
         

            <Footer />
            {/* //////////// */}
        </>
    )
}

export default Gallery